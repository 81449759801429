<template>
  <nav class="nav navbar navbar-expand-lg navbar-light iq-navbar shadow-sm">
    <div class="container-fluid navbar-inner">
      <a href="javascript:void(0)" class="navbar-brand">
        <img class="brand-navbar" src="@assets/images/logo.png" />
      </a>
      <div class="sidebar-toggle" data-toggle="sidebar" data-active="true">
        <i class="icon">
          <span class="fas fa fa-arrow-right" />
        </i>
      </div>
      <ul
        class="navbar-nav ms-auto align-items-center navbar-list mb-2 mb-lg-0"
      >
        <li class="nav-item dropdown">
          <a
            class="nav-link py-0 d-flex align-items-center"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              :src="require(`@assets/images/avatars/${genderAvatar}`)"
              :alt="user.username"
              class="img-fluid avatar avatar-50 avatar-rounded"
            />
            <div class="caption ms-3 d-none d-md-block">
              <h6 class="mb-0 caption-title">{{ user.username }}</h6>
              <p class="mb-0 caption-sub-title">{{ roleName }}</p>
            </div>
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown"
          >
            <!-- <li><hr class="dropdown-divider" /></li> -->
            <li>
              <a class="dropdown-item" @click="logout()">
                <span class="mr-1">
                  <i class="fa fa-sign-out-alt text-danger"></i>
                </span>
                Cerrar Sesión
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'

export default {
  inheritAttrs: false,
  computed: {
    ...mapState({
      user: (state) => state.user.data,
    }),
    roleName() {
      if (this.user) {
        let role = this.user?.role
        return role?.name
      }
      return '-'
    },
    genderAvatar() {
      if (this.user) {
        let gender = this.user.gender
        return parseInt(gender) === 1 ? 'man.png' : 'woman.png'
      }
      return '01.png'
    },
  },
  methods: {
    async logout() {
      this.$parent.logout()
    },
  },
}
</script>
